
import BarcodePDF from '@/components/barcodeImport/BarcodePDF.vue'
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import DataTableMixin from '@/components/mixins/DataTableMixin.vue'
import IconArrowLeft from '@/components/svg/IconArrowLeft.vue'
import IconDownload from '@/components/svg/IconDownload.vue'
import { SET_HAVE_NEW_EXPORT_RECORD } from '@/store/actions'
import { exportBarCode, fetchProjectById, getBarcodeImport, getBarcodeImportDetail } from '@/utils/api'
import errorHandler from '@/utils/errorHandler'
import { openMessage } from '@/utils/utils'
import { maska } from 'maska'
import { EBarcodePublishStatus, EExportType } from 'smartbarcode-web-core/src/utils/enums/index'
import {
  IBarcodeCreationResult,
  IBarcodeDefinitionType,
  IBarcodeImportDetail,
  IBarcodeList,
  ICommonSearch,
  IDimension,
  IProject,
} from 'smartbarcode-web-core/src/utils/types/index'
import { Options } from 'vue-class-component'
import { Mixins, Prop, ProvideReactive } from 'vue-property-decorator'

@Options({
  components: { ItemNotFound, PageHeader, IconDownload, BarcodePDF, IconArrowLeft },
  directives: { maska },
  name: 'BarcodeImportDetail',
})
export default class BarcodeImportDetail extends Mixins(DataTableMixin) {
  @ProvideReactive() projectVersion = 0
  @Prop({ default: '' }) readonly projectCode: string = ''
  loading = false

  data: IBarcodeImportDetail & {
    projectName?: string
    barcodeTypeDefinition?: IBarcodeDefinitionType
  } = {
    projectId: '',
    barcodeType: '',
    operationType: '',
    status: '',
    successCount: 0,
    errorCount: 0,
    totalCount: 0,
    id: '',
    barcodeCreationResults: [],
  }

  dataSearch = {} as ICommonSearch
  project = {} as IProject
  barcodes = [] as Array<IBarcodeList>

  get isDownloadBtnDisabled(): boolean {
    return this.loading || this.data.errorCount > 0 || this.data?.status !== EBarcodePublishStatus.COMPLETED
  }

  get errorBarcodeImportMessage() {
    return this.data?.errorMessage ? this.data?.errorMessage : this.$t('barcodeImport.errors_message')
  }

  tableCellClassName(object: { row: IBarcodeCreationResult; rowIndex: number; columnIndex: number }) {
    if (object.row.errorMessages.length > 0) {
      return 'custom-table__cell cursor-pointer error-row'
    }
    return 'custom-table__cell cursor-pointer'
  }

  goToImportList() {
    this.$router.push({
      name: 'barcodePublish',
      query: {
        projectCode: this.projectCode,
      },
    })
  }

  formatDimension(dimension: IDimension) {
    if (dimension) {
      const arrString = []
      const arrResult = []
      if (dimension?.width) {
        arrString.push(`${this.$t('width')} ${dimension?.width} cm `)
      }
      if (dimension?.depth) {
        arrString.push(`${this.$t('depth')} ${dimension?.depth} cm `)
      }
      if (dimension?.height) {
        arrString.push(`${this.$t('height')} ${dimension?.height} cm `)
      }

      if (arrString.length > 0) {
        arrResult.push(arrString.join(' x '))
      }
      if (dimension?.weight) {
        arrResult.push(`${this.$t('weight')} ${dimension?.weight} kg `)
      }
      return arrResult.join(', ')
    }

    return ''
  }

  formatErrorMessage(errorArr: string[]) {
    return errorArr.join(' ,')
  }

  onRowClick(barcode: IBarcodeCreationResult) {
    if (!barcode.barcodeId) return
    this.$router.push({
      name: 'barcodeView',
      params: { id: barcode.barcodeId },
    })
  }

  async downloadCSV() {
    try {
      this.loading = true
      const payload = {
        projectCode: this.currentSelectProject?.code || '',
        barcodeImportId: this.barcodeImportId,
      }
      await exportBarCode(payload)
      this.$store.commit(SET_HAVE_NEW_EXPORT_RECORD, { exportType: EExportType.BARCODE, isHaveNew: true })
    } catch (e) {
      openMessage(e as string, 'error')
    } finally {
      this.loading = false
    }
  }

  async downloadPDF() {
    try {
      this.loading = true
      const [projectResult, result] = await Promise.all([
        fetchProjectById(this.data.projectId),
        getBarcodeImportDetail(this.barcodeImportId),
      ])
      this.barcodes = result.results
      this.project = projectResult.project
      this.projectVersion = this.project?.version ?? 0
      this.$refs.barcodePDFComponent.showComfirmPopup()
    } catch (error) {
      errorHandler(error)
    } finally {
      this.loading = false
    }
  }

  get barcodeImportId() {
    return this.$route.params.id || ''
  }

  async currentPageChange(pageNum: number) {
    this.currentPageNum = pageNum
    await this.fetchList()
  }

  async fetchList() {
    this.loading = true
    this.isShowTable = false
    this.redirectIndex()

    this.data =
      (await getBarcodeImport({
        id: this.barcodeImportId,
        skip: (this.currentPageNum - 1) * this.itemsPerPage,
        count: this.itemsPerPage,
      })) || {}

    setTimeout(() => {
      this.isShowTable = true
      this.loading = false
    })
  }

  async created() {
    await this.fetchList()
  }
}
